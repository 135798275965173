import { useNavigate } from "react-router-dom";
import { Button, List, Typography } from "antd";
import {
	readFile,
	setFileStorage,
} from "../../../providers/useCapacitorStorage";
import dayjs from "dayjs";

export default function SurveyList(props) {
	const { formList } = props;

	const navigate = useNavigate();

	if (formList && formList.length) {
		return (
			<List
				bordered
				dataSource={formList}
				renderItem={(item, index) => {
					return (
						<List.Item className={index !== formList.length - 1 ? "mb-10" : ""}>
							<div className="wrapper-content">
								<div className="content">
									<Typography.Text>{item.form_name}</Typography.Text>
								</div>

								<div className="action">
									<Button
										className="btn-add-tertiary"
										onClick={() => {
											readFile(
												"dsac_fsuu_evaluation/survey_pending_list.txt"
											).then(async (res) => {
												if (res && res.data) {
													let data = JSON.parse(res.data);

													let lastIndex = data.length + 1;

													let dataForm = {
														...item,
														survey_status: "On Progress",
														date_start_answer: dayjs().format(
															"YYYY-MM-DD HH:mm:ss"
														),
														date_end_answer: null,
														index: lastIndex,
														form_question_categories:
															item.form_question_categories.map((item) => ({
																...item,
																status_answer: "wait",
																form_questions: item.form_questions.map(
																	(item2) => ({
																		...item2,
																		answer: null,
																	})
																),
															})),
													};

													data.push(dataForm);

													setFileStorage(
														"dsac_fsuu_evaluation",
														"survey_pending_list",
														data
													).then(() => {
														navigate(`/survey/${lastIndex}`);
													});
												} else {
													let dataForm = {
														...item,
														survey_status: "On Progress",
														date_start_answer: dayjs().format(
															"YYYY-MM-DD HH:mm:ss"
														),
														date_end_answer: null,
														index: 1,
														form_question_categories:
															item.form_question_categories.map((item) => ({
																...item,
																form_questions: item.form_questions.map(
																	(item2) => ({
																		...item2,
																		answer: null,
																	})
																),
															})),
													};
													setFileStorage(
														"dsac_fsuu_evaluation",
														"survey_pending_list",
														[dataForm]
													).then(() => {
														navigate(`/survey/1`);
													});
												}
											});
										}}
									>
										Take Survey
									</Button>
								</div>
							</div>
						</List.Item>
					);
				}}
			/>
		);
	} else {
		return (
			<div className="div-no-survey-list">
				<div className="content">No Survey Available</div>
			</div>
		);
	}
}
