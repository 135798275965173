import { Form, Input, Radio, Space, Checkbox, Select, DatePicker } from "antd";

export default function QuestionOption(props) {
	const { data } = props;

	if (data && data.question_type === "text") {
		return (
			<Form.Item
				name={`q_${data.id}`}
				rules={[
					{
						required: data.required ? true : false,
						message: "Please answer the question!",
					},
				]}
			>
				<Input placeholder="Answer" size="large" />
			</Form.Item>
		);
	} else if (data && data.question_type === "number") {
		return (
			<Form.Item
				name={`q_${data.id}`}
				rules={[
					{
						required: data.required ? true : false,
						message: "Please answer the question!",
					},
				]}
			>
				<Input placeholder="Answer" size="large" type="number" />
			</Form.Item>
		);
	} else if (data && data.question_type === "radio") {
		return (
			<Form.Item
				name={`q_${data.id}`}
				rules={[
					{
						required: data.required ? true : false,
						message: "Please answer the question!",
					},
				]}
			>
				<Radio.Group size="large">
					<Space direction="vertical">
						{data.form_question_options
							? data.form_question_options.map((item, index) => {
									return (
										<Radio key={index} value={item.option}>
											{item.option}
										</Radio>
									);
							  })
							: ""}
					</Space>
				</Radio.Group>
			</Form.Item>
		);
	} else if (data && data.question_type === "checkbox") {
		return (
			<Form.Item
				name={`q_${data.id}`}
				rules={[
					{
						required: data.required ? true : false,
						message: "Please answer the question!",
					},
				]}
			>
				<Checkbox.Group size="large">
					<Space direction="vertical">
						{data.form_question_options
							? data.form_question_options.map((item, index) => {
									return (
										<Checkbox key={index} value={item.option}>
											{item.option}
										</Checkbox>
									);
							  })
							: ""}
					</Space>
				</Checkbox.Group>
			</Form.Item>
		);
	} else if (data && data.question_type === "select") {
		return (
			<Form.Item
				name={`q_${data.id}`}
				rules={[
					{
						required: data.required ? true : false,
						message: "Please answer the question!",
					},
				]}
			>
				<Select
					placeholder="Select an answer"
					size="large"
					allowClear
					optionFilterProp="children"
					showSearch
					filterOption={(input, option) =>
						(option?.label ?? "").toLowerCase().includes(input.toLowerCase())
					}
					options={
						data.form_question_options
							? data.form_question_options.map((item, index) => ({
									key: index,
									value: item.option,
									label: item.option,
							  }))
							: []
					}
				/>
			</Form.Item>
		);
	} else if (data && data.question_type === "date") {
		return (
			<Form.Item
				name={`q_${data.id}`}
				rules={[
					{
						required: data.required ? true : false,
						message: "Please answer the question!",
					},
				]}
			>
				<DatePicker size="large" format="MM/DD/YYYY" />
			</Form.Item>
		);
	} else {
		return null;
	}
}
