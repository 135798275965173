import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Card, Col, Form, Row, Typography, notification } from "antd";
import dayjs from "dayjs";

import { userData } from "../../../providers/companyInfo";
import { readFile } from "../../../providers/useCapacitorStorage";
import QuestionOption from "./components/QuestionOption";
import FloatSelect from "../../../providers/FloatSelect";
import ModalPreviewAnswer from "./components/ModalPreviewAnswer";
import validateRules from "../../../providers/validateRules";

export default function PageSurveyForm() {
	const params = useParams();
	const navigate = useNavigate();
	const [form] = Form.useForm();

	const [toggleModalPreview, setToggleModalPreview] = useState(false);
	const [dataSource, setDataSource] = useState(null);
	const [dataSubjects, setDataSubjects] = useState([]);
	const [dataFaculties, setDataFaculties] = useState([]);
	const [facultyInfo, setFacultyInfo] = useState({
		name: "",
		id: "",
		subject_id: "",
	});

	useEffect(() => {
		let paramsId = params.id;

		readFile("dsac_fsuu_evaluation/survey_pending_list.txt").then((res) => {
			if (res && res.data) {
				res = JSON.parse(res.data);

				console.log("survey_pending_list res", res);
				let findData = res.find((f) => f.index === parseInt(paramsId));

				console.log("findData", findData);

				if (findData) {
					let question = findData.form_question_categories[0].form_questions[0];
					let subjects = [];

					findData.subjects.forEach((item) => {
						let findFaculty = findData.faculties.find(
							(f) =>
								Number(f.subject_id) === Number(item.subject_id) &&
								f.section_id === item.section_id
						);

						if (findFaculty) {
							subjects.push({
								subject_id: item.subject_id,
								subject_code: item.subject_code,
								section_id: item.section_id,
							});
						}
					});

					setDataSource(findData);
					setDataSubjects(subjects);
					setDataFaculties(findData.faculties);
					form.setFieldsValue({ [`q_${question.id}`]: question.answer });
				} else {
					notification.warning({
						message: "Warning",
						description: "No data was found.",
						duration: 10,
					});
					navigate("/");
				}
			} else {
				notification.warning({
					message: "Warning",
					description:
						"Unable to read the file: either no permission or the file was not found.",
					duration: 10,
				});
			}
		});

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params.id]);

	const onFinish = (values) => {
		let form_question_categories = dataSource.form_question_categories.map(
			(item) => {
				let form_questions = item.form_questions.map((item2) => {
					let answer = values[`q_${item2.id}`] ?? "";

					if (item2.question_type === "date") {
						if (values[`q_${item2.id}`]) {
							answer = dayjs(values[`q_${item2.id}`]).format("YYYY-MM-DD");
						}
					}

					return {
						...item2,
						profile_id: userData().profile_id,
						form_id: dataSource.id,
						date_start_answer: dataSource.date_start_answer,
						date_end_answer: dayjs().format("YYYY-MM-DD HH:mm:ss"),
						form_question_category_id: item.id,
						form_question_id: item2.id,
						answer: Array.isArray(answer) ? JSON.stringify(answer) : answer,
						created_by: userData().id,
						created_at: dayjs().format("YYYY-MM-DD HH:mm:ss"),
						updated_at: dayjs().format("YYYY-MM-DD HH:mm:ss"),
					};
				});

				return {
					...item,
					form_questions,
				};
			}
		);

		let dataSourceCopy = {
			...dataSource,
			form_question_categories,
			date_end_answer: dayjs().format("YYYY-MM-DD HH:mm:ss"),
			survey_status: "Ready to Upload",
		};

		setDataSource(dataSourceCopy);
		setTimeout(() => {
			setToggleModalPreview(true);
		}, 500);
	};

	return (
		<Form form={form} onFinish={onFinish}>
			<Row gutter={[12, 0]}>
				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					<div className="form-name">
						<Typography.Title level={5}>
							{dataSource && dataSource.form_name}
						</Typography.Title>
					</div>
				</Col>

				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					<Card title="Subject">
						<Form.Item name="subject_id" rules={[validateRules.required()]}>
							<FloatSelect
								label="Subject"
								placeholder="Subject"
								required
								options={dataSubjects.map((item) => ({
									value: item.subject_id,
									label: item.subject_code,
								}))}
								onChange={(e) => {
									if (e) {
										let findSubject =
											dataSubjects &&
											dataSubjects.find(
												(f) => Number(f.subject_id) === Number(e)
											);
										if (findSubject) {
											let findFaculty = dataFaculties.find(
												(f) =>
													Number(f.subject_id) === Number(e) &&
													f.section_id === findSubject.section_id
											);

											if (findFaculty) {
												setFacultyInfo({
													name: findFaculty.fullname,
													id: findFaculty.id,
													subject_id: e,
													subject_code: findSubject.subject_code,
												});
											} else {
												setFacultyInfo({
													name: "",
													id: "",
													subject_id: "",
													subject_code: "",
												});
											}
										}
									} else {
										setFacultyInfo({
											name: "",
											id: "",
											subject_id: "",
											subject_code: "",
										});
									}
								}}
							/>
						</Form.Item>

						{facultyInfo.name && (
							<div className="form-faculty-name">
								<Typography.Title level={5}>
									{facultyInfo.name ? `Faculty: ${facultyInfo.name}` : ""}
								</Typography.Title>
							</div>
						)}
					</Card>
				</Col>

				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					{dataSource && dataSource.form_question_categories
						? dataSource.form_question_categories.map((item, index) => {
								return (
									<Card key={index} title={item.category}>
										<Row gutter={[12, 12]}>
											{item.form_questions && item.form_questions
												? item.form_questions.map((item2, index2) => {
														return (
															<Col
																xs={24}
																sm={24}
																md={24}
																lg={24}
																xl={24}
																key={index2}
															>
																<div
																	className="quill-output question"
																	dangerouslySetInnerHTML={{
																		__html: item2.question,
																	}}
																/>

																<QuestionOption data={item2} />
															</Col>
														);
												  })
												: []}
										</Row>
									</Card>
								);
						  })
						: []}
				</Col>

				<Col xs={24} sm={24} md={24} lg={24} xl={24} className="text-center">
					<Button
						htmlType="submit"
						type="primary"
						className="btn-main-primary br-30"
						size="large"
					>
						Preview
					</Button>
				</Col>
			</Row>

			<ModalPreviewAnswer
				toggleModalPreview={toggleModalPreview}
				setToggleModalPreview={setToggleModalPreview}
				dataSource={dataSource}
				facultyInfo={facultyInfo}
			/>
		</Form>
	);
}
