import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, Modal, notification, Typography } from "antd";
import dayjs from "dayjs";

import { POST } from "../../../../providers/useAxiosQuery";
import { userData } from "../../../../providers/companyInfo";
import {
	checkNetworkStatus,
	readFile,
	setFileStorage,
} from "../../../../providers/useCapacitorStorage";
import notificationErrors from "../../../../providers/notificationErrors";
import QuestionOptionPreview from "./QuestionOptionPreview";

export default function ModalPreviewAnswer(props) {
	const { toggleModalPreview, setToggleModalPreview, dataSource, facultyInfo } =
		props;

	const navigate = useNavigate();

	const [isLoadingSurvey, setIsLoadingSurvey] = useState(false);

	const { mutate: mutateSurvey } = POST(
		`api/form_question_answer`,
		"form_question_answer_single_store"
	);

	const onFinishPreview = () => {
		let dataSurvey1 = dataSource;

		let dataSurvey2 = [];

		dataSource.form_question_categories.forEach((item) => {
			let questionAnswers = [];
			item.form_questions.forEach((item2) => {
				questionAnswers.push({
					profile_id: userData().profile_id,
					faculty_load_id: facultyInfo.id,
					subject_id: facultyInfo.subject_id,
					form_id: dataSource.id,
					date_start_answer: dataSource.date_start_answer,
					date_end_answer: dayjs().format("YYYY-MM-DD HH:mm:ss"),
					form_question_category_id: item.id,
					form_question_id: item2.id,
					answer: Array.isArray(item2.answer)
						? JSON.stringify(item2.answer)
						: item2.answer,
					created_by: userData().id,
					created_at: dayjs().format("YYYY-MM-DD HH:mm:ss"),
					updated_at: dayjs().format("YYYY-MM-DD HH:mm:ss"),
				});
			});
			dataSurvey2.push(...questionAnswers);
		});

		setIsLoadingSurvey(true);
		checkNetworkStatus().then((res) => {
			readFile("dsac_fsuu_evaluation/survey_pending_list.txt").then((res1) => {
				if (res1 && res1.data) {
					let data = JSON.parse(res1.data);
					let findData = data.findIndex(
						(f) => parseInt(f.index) === parseInt(dataSource.index)
					);

					if (!res.connected) {
						let globalLoading = document.querySelector(".globalLoading");
						if (globalLoading && !globalLoading.matches(".hide")) {
							globalLoading.classList.add("hide");
						}

						data[findData] = dataSurvey1;

						setFileStorage(
							"dsac_fsuu_evaluation",
							"survey_pending_list",
							data
						).then(() => {
							setIsLoadingSurvey(false);
							notification.info({
								message: "Survey",
								description:
									"You are currently offline, and your survey has been saved on this device.",
							});
							navigate(-1);
						});
					} else {
						mutateSurvey(
							{ data: dataSurvey2 },
							{
								onSuccess: (res2) => {
									if (res2.success) {
										notification.success({
											message: "Survey",
											description: res2.message,
										});

										data = data.filter(
											(f) => parseInt(f.index) !== parseInt(dataSource.index)
										);

										setFileStorage(
											"dsac_fsuu_evaluation",
											"survey_pending_list",
											data
										).then(() => {
											setIsLoadingSurvey(false);
											navigate(-1);
										});
									} else {
										setIsLoadingSurvey(false);
										notification.error({
											message: "Survey",
											description: res2.message,
										});
									}
								},
								onError: (err) => {
									setIsLoadingSurvey(false);
									notificationErrors(err);
								},
							}
						);
					}
				} else {
					setIsLoadingSurvey(false);
					notification.error({
						message: "Survey",
						description:
							"Something went wrong while reading the file. Please try again.",
					});
				}
			});
		});
	};

	return (
		<Modal
			wrapClassName="modal-preview-answer"
			open={toggleModalPreview}
			title={dataSource && dataSource.form_name ? dataSource.form_name : ""}
			onCancel={() => setToggleModalPreview(false)}
			footer={
				<>
					<Button
						size="large"
						onClick={() => setToggleModalPreview(false)}
						loading={isLoadingSurvey}
						className="btn-main-primary outlined br-30"
					>
						Close
					</Button>
					<Button
						size="large"
						onClick={() => onFinishPreview()}
						loading={isLoadingSurvey}
						className="btn-main-primary br-30"
					>
						Submit
					</Button>
				</>
			}
		>
			<Card>
				<Typography.Title level={4} className="mt-0">
					Subject: {facultyInfo.subject_code}
				</Typography.Title>
				<Typography.Title level={4} className="mb-0">
					Faculty Name: {facultyInfo.name}
				</Typography.Title>
			</Card>
			{dataSource && dataSource.form_question_categories
				? dataSource.form_question_categories.map((item, index) => {
						return (
							<Card key={index} title={item.category}>
								{item.form_questions && item.form_questions
									? item.form_questions.map((item2, index2) => {
											return (
												<div
													key={index2}
													className={`question-wrapper ${
														item2.question_type !== "break" ? "border" : ""
													}`}
												>
													<div
														className="quill-output question"
														dangerouslySetInnerHTML={{
															__html: item2.question,
														}}
													/>
													<QuestionOptionPreview data={item2} />
												</div>
											);
									  })
									: ""}
							</Card>
						);
				  })
				: ""}
		</Modal>
	);
}
